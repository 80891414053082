import React, { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Home.css";

gsap.registerPlugin(ScrollTrigger);

const { useLayoutEffect, useRef } = React;

function Home() {
  // 👇 add class to body element
  useEffect(() => {
    document.body.classList.add("home-page");
  }, []);

  // HERO ANIMATION
  const hero = useRef();
  const heroTl = useRef();

  useLayoutEffect(() => {
    const heroAnim = gsap.context(() => {
      heroTl.current = gsap
        .timeline()
        .add("start")
        .to(
          ".home__index-hero",
          {
            x: 0,
            autoAlpha: 1,
            duration: 2,
            ease: "power4.inOut",
          },
          "start"
        )
        .to(
          ".home__hero-logo a",
          {
            y: 0,
            autoAlpha: 1,
            duration: 2,
            ease: "power4.inOut",
          },
          "start"
        )
        .to(
          ".home__hero-tagline p",
          {
            y: 0,
            autoAlpha: 1,
            duration: 2.5,
            ease: "power4.inOut",
          },
          "start"
        )
        .to(
          ".home__home-lab-logo a",
          {
            y: 0,
            autoAlpha: 1,
            duration: 3,
            ease: "power4.inOut",
          },
          "start"
        );
    }, hero);
  }, []);

  // CATALOG ANIMATION
  const catalog = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let catalog = gsap.timeline({
        scrollTrigger: {
          trigger: ".home__related",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "+=500",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      catalog.to(".home__related-item a", {
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        duration: 1,
        ease: "power4.inOut",
      });

      return () => catalog.scrollTrigger.kill();
    });
  }, []);

  return (
    <>
      {/* HOME HERO */}
      <div ref={hero} className="home__hero">
        {/* HERO LOGO VERSION */}
        <div className="home__logo-hero">
          <div className="home__logo-hero-wrap">
            <div
              className="home__hero-logo"
              style={{
                opacity: 1,
                visibility: "inherit",
                transform: "translate(0px, 0px)",
              }}
            >
              <a href="/" aria-label="Back to Diageo innovation home page.">
                <img
                  src="/assets/images/diageo-innovation-logo.svg"
                  alt="Diageo innovation Logo"
                  title="Diageo innovation Logo"
                ></img>
              </a>
            </div>
            <div
              className="home__hero-tagline"
              style={{
                opacity: 1,
                visibility: "inherit",
                transform: "translate(0px, 0px)",
              }}
            >
              <p>
                Presents these exciting <span className="white">new</span>{" "}
                creations from
              </p>
            </div>
            <div
              className="home__home-lab-logo"
              style={{
                opacity: 1,
                visibility: "inherit",
                transform: "translate(0px, 0px)",
              }}
            >
              <a href="/" aria-label="Back to Diageo innovation home page.">
                <img
                  src="/assets/images/The-Lab-Horizontal-Reversed-RGB.svg"
                  alt="Diageo innovation The Labs Logo"
                  title="Diageo innovation The Labs Logo"
                ></img>
              </a>
            </div>
          </div>
        </div>

        {/* RESPONSIVE HERO */}
        <img
          id="mobile-hero"
          className="home__hero-img home__index-hero home__mobile-hero"
          src="/assets/images/web-banner-mobile-v3.jpg"
          title="Diageon innovation Hero Mobile"
          alt="Diageo innovation Hero Mobile"
        ></img>
        <img
          id="desktop-hero"
          className="home__hero-img home__index-hero home__desktop-hero"
          src="/assets/images/web-banner-v3.jpg"
          title="Diageon innovation Hero Desktop"
          alt="Diageo innovation Hero Desktop"
        ></img>
      </div>

      {/* MAIN BRAND CATALOG */}
      <section
        ref={catalog}
        className="full home__related catalog white-bg"
        style={{ opacity: 1, visibility: "inherit" }}
      >
        <div className="home__related-wrapper">
          <div className="home__related-item">
            <a
              aria-label="View the Smirnoff Blue Raspberry Lemonade page."
              href="/smirnoff-blue-raspberry-lemonade"
            >
              <img
                src="/assets/images/smirnoff-blue-raspberry-thumbnail.png"
                alt="Smirnoff Blue Raspberry Lemonade"
                title="Smirnoff Blue Raspberry Lemonade"
              ></img>
            </a>
          </div>
          <div className="home__related-item">
            <a
              aria-label="View the Captain Morgain Vita Coco page."
              href="/captain-morgan-vita-coco"
            >
              <img
                src="/assets/images/captain-morgan-vita-coco-thumbnail.png"
                alt="Captain Morgain Vita Coco"
                title="Captain Morgain Vita Coco"
              ></img>
            </a>
          </div>
          <div className="home__related-item">
            <a
              aria-label="View the Buchanan's Pineapple page."
              href="/buchanans-pineapple"
            >
              <img
                src="/assets/images/buchanans-pineapple-thumbnail.png"
                alt="Buchanan's Pineapple"
                title="Buchanan's Pineapple"
              ></img>
            </a>
          </div>
          <div className="home__related-item">
            <a
              aria-label="View the Baileys Vanilla Mint Shake page."
              href="/baileys-vanilla-mint-shake"
            >
              <img
                src="/assets/images/baileys-vanilla-mint-shake-thumb.png"
                alt="Bailey's Vanilla Mint Shake"
                title="Bailey's Vanilla Mint Shake"
              ></img>
            </a>
          </div>
          <div className="home__related-item">
            <a
              aria-label="View the Baileys S'mores page."
              href="/baileys-smores"
            >
              <img
                src="/assets/images/baileys-smores-thumb.png"
                alt="Bailey's S'mores"
                title="Bailey's S'mores"
              ></img>
            </a>
          </div>
          <div className="home__related-item">
            <a
              aria-label="View the Bulleit Crafted Cocktails page."
              href="/bulleit-crafted-cocktails"
            >
              <img
                src="/assets/images/bulleit_thumb.png"
                alt="Bulleit Crafted Cocktails"
                title="Bulleit Crafted Cocktails"
              ></img>
            </a>
          </div>
          <div className="home__related-item">
            <a
              aria-label="View the Smirnoff Peach Lemonade page."
              href="/smirnoff-peach-lemonade"
            >
              <img
                src="/assets/images/smirnoff-peach_thumb-crop-3.png"
                alt="Smirnoff Peach Lemonade"
                title="Smirnoff Peach Lemonade"
              ></img>
            </a>
          </div>
          <div className="home__related-item">
            <a
              aria-label="View the Crown Royal Whisky Lemonade page."
              href="/crown-royal-whisky-lemonade"
            >
              <img
                src="/assets/images/crown-whiskey-lemonade_thumb-5.png"
                alt="Crown Royal Whisky Lemonade"
                title="Crown Royal Whisky Lemonade"
              ></img>
            </a>
          </div>
        </div>
        <p className="disable-a" style={{ textAlign: "center" }}>
          <a
            className="disable-a dil-btn reverse-btn"
            href="/styleguide"
            aria-label="Go to style guide"
          >
            Style Guide
          </a>
        </p>
      </section>
    </>
  );
}

export default Home;
