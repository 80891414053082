import React, { useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Brands.css";
import "../App.css";
import * as FaIcons from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tips from "../components/Tips.js";
import BrandsSlider from "../components/BrandsSlider.js";
import Fancybox from "../components/Fancybox.js";

gsap.registerPlugin(ScrollTrigger);

const { useLayoutEffect, useRef } = React;

function SmirnoffBlueRaspberryLemonade() {
  useEffect(() => {
    // 👇 add class to body element
    document.body.classList.add("smirnoff-blue-raspberry-lemonade-page");
  }, []);

  // BRAND HERO ANIMATION
  const brand = useRef();
  const brandTl = useRef();

  useLayoutEffect(() => {
    const brandAnim = gsap.context(() => {
      brandTl.current = gsap
        .timeline()
        .add("start")
        .to(
          ".brand__brand-hero",
          {
            y: 0,
            autoAlpha: 1,
            duration: 2,
            ease: "power4.inOut",
          },
          "start"
        )
        .to(
          ".brand__play-btn",
          {
            y: 0,
            autoAlpha: 1,
            duration: 2,
            ease: "power4.inOut",
          },
          "start"
        );
    }, brand);
  }, []);

  // BRAND INTRO
  const brandIntro = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandIntro = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__intro",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandIntro.to(".brand__intro-wrap", {
        autoAlpha: 1,
        y: 0,
        duration: 1,
        ease: "power4.inOut",
      });

      return () => brandIntro.scrollTrigger.kill();
    });
  }, []);

  // BRAND SHARE
  const brandShare = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandShare = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__share",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandShare.to(".brand__share-text", {
        autoAlpha: 1,
        y: 0,
        duration: 1,
        ease: "power4.inOut",
      });

      return () => brandShare.scrollTrigger.kill();
    });
  }, []);

  // BRAND VIDEO
  const brandVideo = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandVideo = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__video-downloads",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandVideo.add("start");
      brandVideo.to(
        ".brand__video-download-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandVideo.to(
        ".brand__video-item",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandVideo.scrollTrigger.kill();
    });
  }, []);

  // BRAND IMAGES
  const brandImages = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandImages = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__image-downloads",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandImages.add("start");
      brandImages.to(
        ".brand__image-download-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandImages.to(
        ".brand__image-item",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandImages.scrollTrigger.kill();
    });
  }, []);

  // BRAND HASHTAGS
  const brandHashtags = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandHashtags = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__hashtag-section",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandHashtags.add("start");
      brandHashtags.to(
        ".brand__hashtag-heading",
        {
          autoAlpha: 1,
          y: 0,
          stagger: 0.2,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandHashtags.to(
        ".brand__hashtag",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandHashtags.scrollTrigger.kill();
    });
  }, []);

  // BRAND COPY
  const brandCopy = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandCopy = gsap.timeline({
        scrollTrigger: {
          trigger: ".brand__message-section",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandCopy.add("start");
      brandCopy.to(
        ".brand__message-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandCopy.to(
        ".brand__message",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandCopy.scrollTrigger.kill();
    });
  }, []);

  // BRAND TIPS
  const brandTips = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandTips = gsap.timeline({
        scrollTrigger: {
          trigger: ".tips__section",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandTips.add("start");
      brandTips.to(
        ".tips__heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandTips.to(
        ".tips__block",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandTips.scrollTrigger.kill();
    });
  }, []);

  // BRAND SLIDER
  const brandSlider = useRef();

  useLayoutEffect(() => {
    window.addEventListener("load", function () {
      let brandSlider = gsap.timeline({
        scrollTrigger: {
          trigger: ".brandsslider__related",
          start: "top 80%",
          // toggleActions: "restart none none reverse",
          end: "bottom bottom",
          markers: false,
          invalidateOnRefresh: true,
        },
      });
      brandSlider.add("start");
      brandSlider.to(
        ".brandsslider__related-heading",
        {
          autoAlpha: 1,
          y: 0,
          duration: 1,
          ease: "power4.inOut",
        },
        "start"
      );
      brandSlider.to(
        ".brandsslider__related-cell a",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );
      brandSlider.to(
        ".brandsslider__related .dil-btn",
        {
          autoAlpha: 1,
          y: 0,
          delay: 0.5,
          stagger: 0.2,
          duration: 0.5,
          ease: "power4.inOut",
        },
        "start"
      );

      return () => brandSlider.scrollTrigger.kill();
    });
  }, []);

  // Copy Functions
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const [isCopied2, setIsCopied2] = useState(false);

  const onCopyText2 = () => {
    setIsCopied2(true);
    setTimeout(() => {
      setIsCopied2(false);
    }, 1000);
  };

  const [isCopied3, setIsCopied3] = useState(false);

  const onCopyText3 = () => {
    setIsCopied3(true);
    setTimeout(() => {
      setIsCopied3(false);
    }, 1000);
  };

  const [isCopied4, setIsCopied4] = useState(false);

  const onCopyText4 = () => {
    setIsCopied4(true);
    setTimeout(() => {
      setIsCopied4(false);
    }, 1000);
  };

  const [isCopied5, setIsCopied5] = useState(false);

  const onCopyText5 = () => {
    setIsCopied5(true);
    setTimeout(() => {
      setIsCopied5(false);
    }, 1000);
  };

  const [isCopied6, setIsCopied6] = useState(false);

  const onCopyText6 = () => {
    setIsCopied6(true);
    setTimeout(() => {
      setIsCopied6(false);
    }, 1000);
  };

  const [isCopied7, setIsCopied7] = useState(false);

  const onCopyText7 = () => {
    setIsCopied7(true);
    setTimeout(() => {
      setIsCopied7(false);
    }, 1000);
  };

  const [isCopied8, setIsCopied8] = useState(false);

  const onCopyText8 = () => {
    setIsCopied8(true);
    setTimeout(() => {
      setIsCopied8(false);
    }, 1000);
  };

  const [isCopied9, setIsCopied9] = useState(false);

  const onCopyText9 = () => {
    setIsCopied9(true);
    setTimeout(() => {
      setIsCopied9(false);
    }, 1000);
  };

  const [isCopied10, setIsCopied10] = useState(false);

  const onCopyText10 = () => {
    setIsCopied10(true);
    setTimeout(() => {
      setIsCopied10(false);
    }, 1000);
  };

  return (
    <>
      {/* HERO */}
      <section ref={brand} className="brand__hero">
        <img
          className="brand__mobile-hero brand__hero-img brand__brand-hero"
          src="/assets/images/Smirnoff-Blue-Raspberry-Posts-4x5.jpg"
          alt="Smirnoff Blue Raspberry Hero Mobile"
          title="Smirnoff Blue Raspberry Hero Mobile"
        />
        <img
          className="brand__desktop-hero brand__hero-img brand__brand-hero"
          src="/assets/images/Smirnoff-Blue-Raspberry-Posts-16x9.jpg"
          alt="Smirnoff Blue Raspberry Hero Desktop"
          title="Smirnoff Blue Raspberry Hero Desktop"
        />
        <Fancybox>
          <a
            rel="noreferrer"
            className="brand__play-btn"
            data-fancybox
            href="https://vimeo.com/791335836"
            aria-label="Play Smirnoff Blue Raspberry sizzle reel."
          >
            <div className="brand__icon blue-gradient-bg">
              <FaIcons.FaPlay className="white" />
            </div>
          </a>
        </Fancybox>
      </section>
      {/* INTRO */}
      <section
        ref={brandIntro}
        className="full brand__intro smirnoff-dark-blue-bg"
      >
        <div className="brand__intro-wrap">
          <h3 className="brand__intro-heading smirnoff-light-blue">
            <span className="smirnoff-yellow">Introducing</span> <br />
            SMIRNOFF BLUE RASPBERRY LEMONADE <br />
            <span className="brand__intro-subtitle smirnoff-magenta">
              One of the hottest flavor trends, from the world’s No. 1 vodka
              brand.
            </span>
          </h3>

          <p className="brand__intro-copy white">
            Smirnoff Blue Raspberry Lemonade combines the nostalgic taste of
            blue raspberry over sweet and refreshing lemonade flavor for a
            taste-bud bursting drink. This vibrant flavor-blasted liquid is
            perfect for chilling and enjoying anytime, anywhere. Whether it's
            enjoying long days outdoors, or celebrating hot summer nights, it
            can be enjoyed as a shot, with seltzer or mixed into lemonade.
          </p>

          <p className="brand__intro-copy white">Available February 2023.</p>
          <p>
            <a
              rel="noreferrer"
              className="dil-btn"
              href="/styleguide"
              aria-label="Go to style guide"
            >
              Style Guide
            </a>
          </p>
        </div>
      </section>

      {/* SHARE BUTTONS */}
      <section
        ref={brandShare}
        className="full brand__share yellow-gradient-bg-2"
      >
        <div className="brand__share-wrap">
          <div className="brand__share-text">
            <h3 className="white">
              <span className="partial-op">Help build our</span>{" "}
              <span className="full-op">brands</span>{" "}
              <span className="partial-op">by</span>{" "}
              <span className="full-op">SHARING</span>{" "}
              <span className="partial-op">them on your</span>{" "}
              <span className="full-op">social media.</span>
            </h3>
          </div>
          <div className="brand__share-feature">
            <h4 className="brand__share-heading heading lime-green">
              Quick Share From Our Social Accounts
            </h4>
            {/* NEW SHARE BUTTONS */}
            <a
              rel="noreferrer"
              href="https://www.facebook.com/DiageoInnovation"
              target="_blank"
              aria-label="Share Smirnoff Blue Raspberry from Facebook."
            >
              <i className="lime-green fab fa-facebook-f"></i>
            </a>
            <a
              rel="noreferrer"
              href="https://twitter.com/DiageoInnovates"
              target="_blank"
              aria-label="Share Smirnoff Blue Raspberry from Twitter."
            >
              <i className="lime-green fab fa-twitter"></i>
            </a>
            <a
              rel="noreferrer"
              href="https://www.instagram.com/diageoinnovation/"
              target="_blank"
              aria-label="Share Smirnoff Blue Raspberry from Instagram."
            >
              <i className="lime-green fab fa-instagram"></i>
            </a>

            {/* FORMER SHARE BUTTONS 
                  <a rel="noreferrer" href="https://www.facebook.com/sharer/sharer.php?u=https://www.baileys.com/en-us/" aria-label="Share Smirnoff Blue Raspberry on Facebook."><i className="lime-green fab fa-facebook"></i></a>
                  <a rel="noreferrer" href="https://twitter.com/intent/tweet?url=https://www.baileys.com/en-us/&text=" aria-label="Share Smirnoff Blue Raspberry on Twitter."><i className="lime-green fab fa-twitter"></i></a>
                  <a rel="noreferrer" href="https://www.linkedin.com/sharing/share-offsite/?url=https://www.baileys.com/en-us/" aria-label="Share Smirnoff Blue Raspberry on LinkedIn."><i className="lime-green fab fa-linkedin-in"></i></a> */}
          </div>
        </div>
      </section>

      {/* VIDEO DOWNLOADS */}
      <section
        ref={brandVideo}
        id="video-downloads"
        className="full brand__video-downloads smirnoff-light-blue-bg"
      >
        <h4 className="brand__video-download-heading heading smirnoff-dark-blue">
          <span className="white">DOWNLOAD</span> these READY-TO-SHARE{" "}
          <span className="white">Videos.</span>
        </h4>
        <div className="brand__video-downloads-wrapper" video-rollover="1">
          <div className="brand__video-item">
            <div
              id="brand__video-item-1"
              className="brand__download-wrap brand__video-rollover smirnoff-blue-bg"
            >
              <img
                src="/assets/images/Smirnoff-Blue-Raspberry-Posts-1x1.jpg"
                alt="Smirnoff Blue Raspberry 1x1 Thumbnail"
                title="Smirnoff Blue Raspberry 1x1 Thumbnail"
              />

              <div className="brand__video-item-btns">
                <a
                  rel="noreferrer"
                  href="https://player.vimeo.com/progressive_redirect/download/791335795/rendition/1080p/smirnoff-blue-raspberry-posts-1x1.mp4%20%281080p%29.mp4?loc=external&signature=7175f76922604bd34396e1b794dc08ef863f511352fe37432a9167e8bc7e50a5"
                  download
                  aria-label="Download Smirnoff Blue Raspberry 1x1 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaArrowDown className="white" />
                    <span>Download</span>
                  </div>
                </a>

                <a
                  data-fancybox
                  href="https://vimeo.com/791335795"
                  aria-label="Preview Smirnoff Blue Raspberry 1x1 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaEye className="white" />
                    <span>Preview</span>
                  </div>
                </a>
              </div>
            </div>
            <p>
              <strong>Square 1x1</strong>
              <br />
              <em className="smirnoff-dark-blue">
                Facebook, LinkedIn, or Instagram Posts
              </em>
            </p>
          </div>
          <div className="brand__video-item">
            <div
              id="brand__video-item-2"
              className="brand__download-wrap brand__video-rollover smirnoff-blue-bg"
            >
              <img
                src="/assets/images/Smirnoff-Blue-Raspberry-Posts-9x16.jpg"
                alt="Smirnoff Blue Raspberry 9x16 Thumbnail"
                title="Smirnoff Blue Raspberry 9x16 Thumbnail"
              />

              <div className="brand__video-item-btns">
                <a
                  rel="noreferrer"
                  href="https://player.vimeo.com/progressive_redirect/download/791335819/rendition/720p/smirnoff-blue-raspberry-posts-9x16%20%28720p%29.mp4?loc=external&signature=7fbe90c89cd6c245b8f559dae372b1719c80332448d50133e9e69527cfc905f2"
                  download
                  aria-label="Download Smirnoff Blue Raspberry 9x16 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaArrowDown className="white" />
                    <span>Download</span>
                  </div>
                </a>

                <a
                  data-fancybox
                  href="https://vimeo.com/791335819"
                  aria-label="Preview Smirnoff Blue Raspberry 9x16 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaEye className="white" />
                    <span>Preview</span>
                  </div>
                </a>
              </div>
            </div>
            <p>
              <strong>Vertical 9x16</strong>
              <br />
              <em className="smirnoff-dark-blue">
                Facebook or Instagram Stories
              </em>
            </p>
          </div>
          <div className="brand__video-item">
            <div
              id="brand__video-item-3"
              className="brand__download-wrap brand__video-rollover smirnoff-blue-bg"
            >
              <img
                src="/assets/images/Smirnoff-Blue-Raspberry-Posts-4x5.jpg"
                alt="Smirnoff Blue Raspberry 4x5 Thumbnail"
                title="Smirnoff Blue Raspberry 4x5 Thumbnail"
              />

              <div className="brand__video-item-btns">
                <a
                  rel="noreferrer"
                  href="https://player.vimeo.com/progressive_redirect/download/791335805/rendition/1080p/smirnoff-blue-raspberry-posts-4x5%20%281080p%29.mp4?loc=external&signature=08a84667334a0e1ead8302f3e9109d16a164f9aef598f39669249d0bb9617ac9"
                  download
                  aria-label="Download Smirnoff Blue Raspberry 4x5 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaArrowDown className="white" />
                    <span>Download</span>
                  </div>
                </a>

                <a
                  data-fancybox
                  href="https://vimeo.com/791335805"
                  aria-label="Preview Smirnoff Blue Raspberry 4x5 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaEye className="white" />
                    <span>Preview</span>
                  </div>
                </a>
              </div>
            </div>
            <p>
              <strong>Vertical 4x5</strong>
              <br />
              <em className="smirnoff-dark-blue">
                Facebook, LinkedIn, or Instagram Posts
              </em>
            </p>
          </div>
          <div className="brand__video-item">
            <div
              id="brand__video-item-4"
              className="brand__download-wrap brand__video-rollover smirnoff-blue-bg"
            >
              <img
                src="/assets/images/Smirnoff-Blue-Raspberry-Posts-16x9.jpg"
                alt="Smirnoff Blue Raspberry 16x9 Thumbnail"
                title="Smirnoff Blue Raspberry 16x9 Thumbnail"
              />

              <div className="brand__video-item-btns">
                <a
                  rel="noreferrer"
                  href="https://player.vimeo.com/progressive_redirect/download/791335836/rendition/720p/smirnoff-blue-raspberry-posts-16x9%20%28720p%29.mp4?loc=external&signature=a60bbfa90f41e4c12ad5e498b5830c130c590eb0b5969268953e3183ee770aa1"
                  download
                  aria-label="Download Smirnoff Blue Raspberry 16x9 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaArrowDown className="white" />
                    <span>Download</span>
                  </div>
                </a>

                <a
                  data-fancybox
                  href="https://vimeo.com/791335836"
                  aria-label="Preview Smirnoff Blue Raspberry 16x9 Video"
                >
                  <div className="brand__video-download-preview">
                    <FaIcons.FaEye className="white" />
                    <span>Preview</span>
                  </div>
                </a>
              </div>
            </div>
            <p>
              <strong>Horizontal 16x9</strong>
              <br />
              <em className="smirnoff-dark-blue">
                Facebook, LinkedIn, or Twitter Posts
              </em>
            </p>
          </div>
        </div>
      </section>

      {/* IMAGE DOWNLOADS */}
      <section
        ref={brandImages}
        className="full brand__image-downloads smirnoff-blue-bg"
      >
        <h4 className="brand__image-download-heading heading smirnoff-light-blue">
          <span className="white">DOWNLOAD</span> these READY-TO-SHARE{" "}
          <span className="white">Images.</span>
        </h4>

        <div className="brand__image-downloads-wrapper">
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap smirnoff-light-blue-bg"
              href="/assets/images/Smirnoff-Blue-Raspberry-Posts-1x1.jpg"
              aria-label="Download Smirnoff Blue Raspberry 1x1 image"
              download
            >
              <img
                src="/assets/images/Smirnoff-Blue-Raspberry-Posts-1x1.jpg"
                alt="Smirnoff Blue Raspberry 1x1 thumbnail"
                title="Smirnoff Blue Raspberry 1x1 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong>Square 1x1</strong>
              <br />
              <em className="smirnoff-light-blue">
                Facebook, LinkedIn, or Instagram Posts
              </em>
            </p>
          </div>
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap smirnoff-light-blue-bg"
              href="/assets/images/Smirnoff-Blue-Raspberry-Posts-9x16.jpg"
              aria-label="Download Smirnoff Blue Raspberry 9x16 image"
              download
            >
              <img
                src="/assets/images/Smirnoff-Blue-Raspberry-Posts-9x16.jpg"
                alt="Smirnoff Blue Raspberry 9x16 thumbnail"
                title="Smirnoff Blue Raspberry 9x16 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong>Vertical 9x16</strong>
              <br />
              <em className="smirnoff-light-blue">
                Facebook or Instagram Stories
              </em>
            </p>
          </div>
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap smirnoff-light-blue-bg"
              href="/assets/images/Smirnoff-Blue-Raspberry-Posts-4x5.jpg"
              aria-label="Download Smirnoff Blue Raspberry 4x5 image"
              download
            >
              <img
                src="/assets/images/Smirnoff-Blue-Raspberry-Posts-4x5.jpg"
                alt="Smirnoff Blue Raspberry 4x5 thumbnail"
                title="Smirnoff Blue Raspberry 4x5 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong>Vertical 4x5</strong>
              <br />
              <em className="smirnoff-light-blue">
                Facebook, LinkedIn, or Instagram Posts
              </em>
            </p>
          </div>
          <div className="brand__image-item">
            <a
              rel="noreferrer"
              className="brand__download-wrap smirnoff-light-blue-bg"
              href="/assets/images/Smirnoff-Blue-Raspberry-Posts-16x9.jpg"
              aria-label="Download Smirnoff Blue Raspberry 16x9 image"
              download
            >
              <img
                src="/assets/images/Smirnoff-Blue-Raspberry-Posts-16x9.jpg"
                alt="Smirnoff Blue Raspberry 16x9 thumbnail"
                title="Smirnoff Blue Raspberry 16x9 thumbnail"
              />
              <div className="brand__image-download-preview">
                <FaIcons.FaArrowDown className="white" />
                <span>Download</span>
              </div>
            </a>
            <p>
              <strong>Horizontal 16x9</strong>
              <br />
              <em className="smirnoff-light-blue">
                Facebook, LinkedIn, or Twitter Posts
              </em>
            </p>
          </div>
        </div>
      </section>

      {/* HASHTAG SECTION */}
      <section
        ref={brandHashtags}
        className="full brand__hashtag-section red-gradient-bg"
      >
        <div className="brand__required-hashtag">
          <h4 className="brand__hashtag-heading heading yellow">
            REQUIRED HASHTAGS
          </h4>
          <div className="brand__hashtag-wrapper">
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#IWorkForSmirnoff</strong>
              </p>
              <CopyToClipboard text="#IWorkForSmirnoff" onCopy={onCopyText}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#DiageoInnovation</strong>
              </p>
              <CopyToClipboard text="#DiageoInnovation" onCopy={onCopyText2}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied2 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <div className="grab-hashtag">
          <h4 className="brand__hashtag-heading heading yellow">
            OPTIONAL HASHTAGS
          </h4>
          <div className="brand__hashtag-wrapper">
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#DiageoInnovation</strong>
              </p>
              <CopyToClipboard text="#DiageoInnovation" onCopy={onCopyText3}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied3 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#FromtheLab</strong>
              </p>
              <CopyToClipboard text="#FromtheLab" onCopy={onCopyText4}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied4 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">
                  #SmirnoffBlueRaspberryLemonade
                </strong>
              </p>
              <CopyToClipboard
                text="#SmirnoffBlueRaspberryLemonade"
                onCopy={onCopyText5}
              >
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied5 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#BlueRaspberrry</strong>
              </p>
              <CopyToClipboard text="#BlueRaspberrry" onCopy={onCopyText6}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied6 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
            <div className="brand__copy-block brand__hashtag">
              <p>
                <strong className="white">#Smirnoff</strong>
              </p>
              <CopyToClipboard text="#Smirnoff" onCopy={onCopyText7}>
                <div className="copy-area">
                  <button className="brand__copy-btn">COPY</button>
                  <span
                    className={`brand__copy-feedback ${
                      isCopied7 ? "brand__active" : ""
                    }`}
                  >
                    Copied!
                  </span>
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </section>

      {/* MESSAGE SECTION */}
      <section
        ref={brandCopy}
        className="full brand__message-section smirnoff-dark-blue-bg"
      >
        <h4 className="brand__message-heading heading">
          <span className="smirnoff-light-blue">
            NEED MESSAGE POINTS ABOUT THIS INNOVATION?
          </span>
        </h4>
        <div className="brand__message-wrapper">
          <div className="brand__copy-block brand__message">
            <h4 className="white">News Style</h4>

            <p className="white" id="NewsStyle">
              From the world of Pink Lemonade and Peach Lemonade comes{" "}
              <strong>Smirnoff Blue Raspberry Lemonade</strong>, combining the
              nostalgic taste of blue raspberry over sweet and refreshing
              lemonade flavor for a taste-bud bursting drink. This
              flavor-blasted liquid is perfect for anywhere, anytime moments,
              enjoyed as a shot, with seltzer or mixed into lemonade.
            </p>
            <CopyToClipboard
              text="From the world of Pink Lemonade and Peach Lemonade comes Smirnoff Blue Raspberry Lemonade, combining the nostalgic taste of blue raspberry over sweet and refreshing lemonade flavor for a taste-bud bursting drink. This flavor-blasted liquid is perfect for anywhere, anytime moments, enjoyed as a shot, with seltzer or mixed into lemonade."
              onCopy={onCopyText8}
            >
              <div className="copy-area">
                <button className="brand__copy-btn">COPY</button>
                <span
                  className={`brand__copy-feedback ${
                    isCopied8 ? "brand__active" : ""
                  }`}
                >
                  Copied!
                </span>
              </div>
            </CopyToClipboard>
          </div>
          <div className="brand__copy-block brand__message">
            <h4 className="white">Casual</h4>

            <p className="white" id="Casual">
              <strong>Smirnoff Blue Raspberry Lemonade</strong> brings the
              nostalgic taste of blue raspberry over sweet and refreshing
              lemonade flavor for a taste-bud bursting drink. Whether it's
              enjoying long days outdoors, or celebrating hot summer nights,
              this vibrant flavor-blasted liquid can be enjoyed as a shot, with
              seltzer or mixed into lemonade. Coming February 2023.
            </p>
            <CopyToClipboard
              text="Smirnoff Blue Raspberry Lemonade brings the nostalgic taste of blue raspberry over sweet and refreshing lemonade flavor for a taste-bud bursting drink. Whether it's enjoying long days outdoors, or celebrating hot summer nights, this vibrant flavor-blasted liquid can be enjoyed as a shot, with seltzer or mixed into lemonade. Coming February 2023."
              onCopy={onCopyText9}
            >
              <div className="copy-area">
                <button className="brand__copy-btn">COPY</button>
                <span
                  className={`brand__copy-feedback ${
                    isCopied9 ? "brand__active" : ""
                  }`}
                >
                  Copied!
                </span>
              </div>
            </CopyToClipboard>
          </div>
          <div className="brand__copy-block brand__message">
            <h4 className="white">Short & Fun</h4>

            <p className="white" id="ShortFun">
              Level up your party vibes with Smirnoff Blue Raspberry Lemonade. A
              flavor-blasted, taste-bud bursting drink coming in February 2023.
            </p>
            <CopyToClipboard
              text="Level up your party vibes with Smirnoff Blue Raspberry Lemonade. A flavor-blasted, taste-bud bursting drink coming in February 2023."
              onCopy={onCopyText10}
            >
              <div className="copy-area">
                <button className="brand__copy-btn">COPY</button>
                <span
                  className={`brand__copy-feedback ${
                    isCopied10 ? "brand__active" : ""
                  }`}
                >
                  Copied!
                </span>
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </section>

      {/* TIPS */}
      <Tips ref={brandTips} />

      {/* BRANDS SLIDER */}
      <BrandsSlider ref={brandSlider} />
    </>
  );
}

export default SmirnoffBlueRaspberryLemonade;
