import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <footer className="full">
      {/*     
            DISABLE THIS IF YOU ARE USING DIAGEO SCRIPT 
            ENABLE IN THE INDEX.HTML FILE WHEN PUSHING TO PRODUCTION
        */}
      {/* <div className="footer__logos">
            <img src="/assets/images/johnnie-walker-logo.svg" alt="Johnnie Walker Logo" title="Johnnie Walker Logo"/>
            <img src="/assets/images/crown-royal-logo.svg" alt="Crown Royal Logo" title="Crown Royal Logo"/>
            <img src="/assets/images/jb-logo.svg" alt="JB Logo" title="JB Logo"/>
            <img src="/assets/images/buchanans-logo.svg" alt="Buchanans Logo" title="Buchanans Logo"/>
            <img src="/assets/images/windsor-logo.svg" alt="Windsor Loo" title="Windsor Loo"/>
            <img src="/assets/images/don-julio-logo.svg" alt="Don Julio Logo" title="Don Julio Logo"/>
            <img src="/assets/images/smirnoff-logo.svg" alt="Smirnoff Logo" title="Smirnoff Logo"/>
            <img src="/assets/images/ketel-one-logo.svg" alt="Ketel One Logo" title="Ketel One Logo"/>
            <img src="/assets/images/ciroc-logo.svg" alt="Ciroc Logo" title="Ciroc Logo"/>
            <img src="/assets/images/captain-morgan-logo.svg" alt="Captain Morgan Logo" title="Captain Morgan Logo"/>
            <img src="/assets/images/baileys-logo.svg" alt="Baileys Logo" title="Baileys Logo"/>
            <img src="/assets/images/tanqueray-logo.svg" alt="Tanqueray Logo" title="Tanqueray Logo"/>
            <img src="/assets/images/guinness-logo.svg" alt="Guinness Logo" title="Guinness Logo"/>
        </div> */}
      <div id="footer"></div>
    </footer>
  );
}

export default Footer;
